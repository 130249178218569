import React from 'react';
import './App.css';

function Header() {
  return (
    <header>
      <a href="https://www.vedo.com.br/" target="_self">
        <img src="logov.png" alt="Logo" className="logo" />
      </a>
      <nav className="navbar">
        {/* Conteúdo da barra de navegação, se aplicável */}
      </nav>
    </header>
  );
}

export default Header;
