import React from 'react';

function Footer() {
  return (
    <footer>
      <img src="https://www.paypalobjects.com/webstatic/pt_PT/mktg/logo-center/bdg_powered_by_pp_pt.png" alt="Check out with PayPal" />
      <p>
        Compra e Venda de Domínios de Internet Genéricos <p>Contatos: dominios@vedo.com.br  11 5050-2769</p>
        <br />
      </p>

    </footer>

  );
}

export default Footer;
