import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './App.css';

const App = () => {


  return (
    <div className="app-container">
      <Header />
      <h1>GAGUINHO.COM.BR</h1>
      <h2>Este nome de domínio está a venda</h2>
      <div className="iframe-container">
        <iframe
          width="400"
          height="315"
          src="https://www.youtube.com/embed/-pMJY56rOX0?si=C2y_C4eKRO-Dil2X"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </div>

      <Footer/>

    </div>
  );
};

export default App;